import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Footer from "../components/Footer";


const Security = ({ title }) => {
    document.querySelector('title').innerHTML = title

    return (
        <Layout>
            <div className="metfeaturesals-page">
                <section style={{ overflow: 'hidden' }}>
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="10px">
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>SECURITY</Typography>
                                <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>
                                    Why your assets are <br />safe with us
                                </Typography>
                                <Spacebox padding="10px" />
                                <Typography>
                                    Customer safety is our first goal - this is how we approach security.
                                </Typography>
                                <Spacebox padding="20px" />
                            </div>
                            {<div className="" style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                <img className="" style={{ width: '60%', display: 'block', margin: 'auto', filter: 'brightness(75%)' }} src="/assets/header-btc.png" alt="star" />
                            </div>}
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px', textAlign: 'center' }}>
                            Security at Mercuryo
                        </Typography>
                        <Spacebox padding="10px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'stretch' }}>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Financial Transparency</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Not only are we the leading European crypto investment platform, we are also one of the most regulated. We believe in doing things the right way, not the easy way, which is why we choose to be fully transparent, to acquire licences that see us monitored by multiple financial market authorities, and to have our funds and business practices regularly validated by external parties.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>User data protection</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Mercuryo only processes your personal data for the purposes permitted according to GDPR. We do not use personal data for automated decision-making, including profiling, and we have several security measures in place to make sure your data is safely stored and processed.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Regulations, licences, registrations</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    The Mercuryo Group diligently follows European laws and regulations. We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Asset protection</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    We prioritise keeping your assets safe, incorporating multiple security measures to protect them from potential threats. Crypto assets are stored in highly-secure cold storage facilities that are examined by an external auditor. Your assets are yours.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Advanced Encryption</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Mercuryo infrastructure and systems are secured with the most modern encryption technologies and supported by our dedicated security teams.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>24/7 Coverage</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    As the markets are open 24/7, so are we. Our Operations, Security and Engineering teams operate on a 24/7 coverage model to respond to any changes within the markets.
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-grey white">
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>
                            Keeping your account secure
                        </Typography>
                        <Spacebox padding="10px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'stretch' }}>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Password Management</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    User passwords are stored using the most trusted and modern hashing algorithms. Nobody can read or decrypt them, including us.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>MFA</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    If activated by the user (highly recommended by Mercuryopal) time-based one-time passwords and multi-factor authentication to defend against security threats and additional 2FA in case of user password compromise.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Session Management</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Mercuryo allows users to see which browsers and devices they are logged in from to control their account access.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>KYC / AML</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Robust KYC onboarding and verification procedures, and fully compliant with European regulation.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Account Lock</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Users are prompted via email to verify every password reset request and to freeze their account if the request was not initiated by them.
                                </Typography>
                            </div>
                            <div style={{ background: '#fff', padding: '20px', borderRadius: '20px' }}>
                                <Typography style={{ fontSize: isMobile ? '25px' : '30px', lineHeight: '45px' }}>Account monitoring</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: '18px' }}>
                                    Important notifications to the user to ensure overview of user account activity.
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <Footer className="pre-white grey" />
            </div>
        </Layout>
    );
}

export default Security;