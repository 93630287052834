import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import { Container, Divider, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Footer from "../components/Footer";
import Helpform from "../components/Helpform";
import Notice from "../components/Notice";
import Grid from "../components/styles/Grid";
import { Link } from "react-router-dom";


const Helpdesk = ({ title }) => {
    document.querySelector('title').innerHTML = title

    return (
        <Layout>
            <div className="help-page">
                <section>
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography className="bold" style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>I'm new to Mercuryo Pal. Where should I start?</Typography>
                        <small>
                            This step by step walkthrough will help you to get started with our platform quickly and seamlessly.
                        </small>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2}>
                            <div>
                                <Typography variant="h5">
                                    Create, verify and secure your account
                                </Typography>
                                <Spacebox padding="20px" />
                                <span style={{ lineHeight: '28px' }}>
                                    The first step in joining the Mercuryopal family is creating an account, which is easy and only takes a couple of minutes of your time:
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> Opening and maintaining a Mercuryopal account is 100% free of charge.
                                </Notice>
                                <span style={{ lineHeight: '28px' }}>
                                    Next, Mercuryopal users are required to verify their Mercuryopal accounts due to the AML5 EU regulation. Verification is a quick and easy process, which we are happy to support you with in the following article:
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> To use our services, you need to be at least 18 years of age. Additionally, only European residents can use the Pro dashboard at this time, our other customers are free to use the <b>Lite</b> dashboard.
                                </Notice>
                                <span style={{ lineHeight: '28px' }}>
                                    Verified? Great – now it is time to make sure your account is safe. As your security is our priority, we strongly recommend that you enable two-factor authentication (2FA).
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> Do not give anyone remote access to your device and do not share your login details with anyone. Mercuryopal will never ask for your password or personal information.
                                </Notice>
                                <Spacebox padding="20px" />
                                <Divider sx={{ opacity: .6 }} />
                                <Spacebox padding="20px" />
                                <Typography variant="h5">
                                    Which services does MercuryoPal offer?
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    Now that you have successfully verified and secured your account, you can start using the full scope of Mercuryopal.
                                </span>
                                <Spacebox padding="20px" />
                                <Typography variant="h5">
                                    Manage your fiat wallet and buy or sell cryptocurrencies
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    Through your personal fiat wallet, you may deposit funds to or withdraw them from your account at any time. Also, your wallet gives you the option to buy and sell cryptocurrencies 24/7.
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> Please note that any bank account you want to use on Mercuryopal for deposits and withdrawals must be in your name, with the minimum amount for fiat transactions being EUR 10.
                                </Notice>
                                <Notice color="info">
                                    <b>Note:</b> Linking of bank account is reserved for European residents only. Non European residents are assigned a BTC address upon registration. You can use this address to fund your Mercuryopal account.
                                </Notice>
                                <Notice color="warning">
                                    <b>Tip:</b> If your bank supports SEPA Instant, transfers up to EUR 100,000 arrive within minutes. Otherwise, it might take 1-3 business days.
                                </Notice>
                                <Typography variant="h5">
                                    Deposit and send cryptocurrencies
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    If you send coins to your deposit addresses, your Mercuryo wallet will be automatically credited with the correct amount, after confirmation by the blockchain network.
                                </span>
                                <Spacebox padding="20px" />
                                <Typography variant="h5">
                                    Buy and sell Mercuryopal Stocks or ETFs
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    In addition to cryptocurrencies, you can also buy and sell a wide range of Mercuryopal Stocks and ETFs (exchange-traded funds).
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> Mercuryopal Stocks enables you to make fractional investments. To allow for this, Mercuryopal issues a derivative contract which is backed by the underlying stock or ETF.
                                </Notice>

                                <Typography variant="h5">
                                    Buy and sell Mercuryopal metals
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    With Mercuryopal Metals, we give you the ability to trade and swap gold, silver, palladium and platinum on Mercuryopal. You will be trading metal tokens that are backed by physical metal supplies safely stored in a secure vault in Switzerland.
                                </span>
                                <Notice color="warning">
                                    <b>Note:</b> For more detailed information, please refer to the article: <br />
                                    <Link to="/metals"><small>What is Mercuryopal Metals</small></Link>
                                </Notice>

                                <Typography variant="h5">
                                    Any questions?
                                </Typography>
                                <span style={{ lineHeight: '28px' }}>
                                    If you still have open questions, please do not hesitate to contact us by email ( <a href="mailto:support@mercuryopal.com">support@mercuryopal.com</a> ) or using the form below.
                                </span>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography className="bold" style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px', textAlign: 'center' }}>Questions? We're here for you</Typography>
                        <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                            Contact us to get more info about products and custom solutions
                        </Typography>
                        <Spacebox padding="20px" />
                        <Helpform />
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <Footer className="pre-grey white" />
            </div>
        </Layout>
    );
}

export default Helpdesk;