import { Suspense, lazy } from 'react';
import { createTheme } from '@mui/material'
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loading from './components/Loading';
import Cryptocurrency from './pages/Cryptocurrency';
import Stocks from './pages/Stocks';
import Metals from './pages/Metals';
import Commodities from './pages/Commodities';
import Features from './pages/Features';
import Company from './pages/Company';
import Security from './pages/Security';
import Helpdesk from './pages/Helpdesk';


const Home = lazy(() => import('./pages/Home'))


function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#5533FF'
      },
      secondary: {
        main: '#7badf9'
      }
    },

    typography: {
      fontFamily: "Gilroy-Medium",
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightBold: 700,
      fontSize: 18
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Loading />}>
        <Router>
          <Routes>
            <Route exact path='/' element={<Home title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/cryptocurrency' element={<Cryptocurrency title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/invest' element={<Cryptocurrency title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/stocks' element={<Stocks title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/efts' element={<Stocks title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/metals' element={<Metals title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/commodities' element={<Commodities title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/features' element={<Features title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/about' element={<Company title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/security' element={<Security title="Mercuryo Investment Pal | Crypro Made Easy" />} />
            <Route exact path='/helpdesk' element={<Helpdesk title="Mercuryo Investment Pal | Crypro Made Easy" />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
