import { Divider, Typography } from "@mui/material";
import { useState } from "react";
import Flexbox from "./Flexbox";
import { ChevronRight } from "@mui/icons-material";
import Spacebox from "./styles/Spacebox";

const FAQ = ({ title, content }) => {
    const [clicked, setClicked] = useState(false)
    return (
        <div className="faq">
            <div onClick={() => setClicked(!clicked)}>
                <Flexbox justifyContent="space-between" alignItems="center">
                    <Typography>
                        {title}
                    </Typography>
                    <ChevronRight style={{ transform: `rotate(${clicked ? 90 : 0}deg)`, fontSize: '18px' }} />
                </Flexbox>
            </div>
            <Spacebox padding="5px" />
            <Divider style={{ opacity: .6 }} />
            {clicked && <div>
                <Spacebox padding="5px" />
                <span>
                    {content}
                </span>
            </div>}
            <Spacebox padding="10px" />
        </div>
    );
}

export default FAQ;