import { useState } from "react";
import Spacebox from "./styles/Spacebox";
import CustomButton from "./styles/Custombutton";
import Toast from "./Toast";

const Helpform = () => {

    const [fullname, setFullname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    const [msg, setMsg] = useState('')
    const [showToast, setShowToast] = useState(false)
    const closeToast = timer => setTimeout(() => {
        setShowToast(false)
    }, timer);


    const sendRequest = () => {
        if (fullname !== '' && email.includes("@") && phone.length > 8 && message !== "") {
            fetch(`https://invest.mercuryopal.com/api/send-mail?email=${email}&fullname=${fullname}&phone=${phone}&message=${message}`)
                .then(res => res.json())
                .then(res => {
                    if (res.success) {
                        setMsg('Request sent')
                        setShowToast(true)
                        closeToast(4000)
                    } else {
                        setMsg('An error occured, try again later')
                        setShowToast(true)
                        closeToast(4000)
                    }
                }).catch(err => {
                    console.log(err)
                    setMsg('Request sent')
                    setShowToast(true)
                    closeToast(4000)
                })
                setFullname('')
                setEmail('')
                setPhone('')
                setMessage('')
        } else {
            setMsg('Invalid request')
            setShowToast(true)
            closeToast(4000)
        }
    }

    return (
        <div className="help-form">
            <Toast msg={msg} showToast={showToast} />
            <div className="mid margin-auto">
                <span>Full Name*</span>
                <Spacebox padding="5px" />
                <input
                    type="text"
                    className="fullname"
                    id="fullname"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                />
                <Spacebox padding="10px" />
                <span>Email*</span>
                <Spacebox padding="5px" />
                <input
                    type="email"
                    className="email"
                    id="fullname"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Spacebox padding="10px" />
                <span>Phone*</span>
                <Spacebox padding="5px" />
                <input
                    type="tel"
                    className="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                />
                <Spacebox padding="10px" />
                <span>Message*</span>
                <Spacebox padding="5px" />
                <textarea
                    type="tel"
                    className="phone"
                    id="phone"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <Spacebox padding="10px" />
                <CustomButton style={{ background: 'var(--primary)', color: 'white', padding: '20px 0', borderRadius: '10px' }} className="fullwidth" handleClick={() => {
                    sendRequest()
                }}>
                    Submit
                </CustomButton>
            </div>
        </div>
    );
}

export default Helpform;