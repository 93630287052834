
const Toast = ({msg, showToast}) => {

    return (
        <div className="toast-body">
            {showToast && <div className="toast">
                {msg}
            </div>}
        </div>
    );
}
 
export default Toast;