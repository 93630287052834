import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomButton from "../components/styles/Custombutton";
import Footer from "../components/Footer";

const Cryptocurrency = ({ title }) => {
    document.querySelector('title').innerHTML = title
    return (
        <Layout>
            <div className="cryptocurrency-page">
                <section>
                    <Container>
                        <Grid gap="20px" grid={isMobile ? 1 : 2} style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>BITCOIN • ETHEREUM • POLKADOT • 400+ MORE</Typography>
                                <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>Join the crypto {!isMobile && <br />}movement</Typography>
                                <Spacebox padding="10px" />
                                <small>Invest in Bitcoin, Ethereum, Polkadot and more fast, secure and 24/7.</small>
                                <Spacebox padding="10px" />
                                {!isMobile && <CustomButton
                                    style={{ padding: '20px 60px', background: 'var(--primary)', borderRadius: '5px', color: 'white' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                                {isMobile && <CustomButton
                                    style={{ padding: '20px 0px', background: 'var(--primary)', borderRadius: '5px', color: 'white', width: '100%' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                            </div>
                            {!isMobile && <img src="/assets/crypto-banner.png" alt="bitcoin" style={{ width: '80%', filter: 'drop-shadow(10px 10px 0px var(--primary))' }} />}
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 3} gap="20px">
                            <div style={{ borderRight: '1px solid #f0f0f0', padding: '20px' }}>
                                <Typography style={{ fontSize: '10px', textAlign: 'center', letterSpacing: '3px' }}>
                                    INVEST
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>
                                    Get started from just $1,300
                                </Typography>
                                <Spacebox padding="5px" />
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    <small>
                                        Buy a piece of Bitcoin. Invest in the coins and tokens you believe in starting from $1,300.
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ borderRight: '1px solid #f0f0f0', padding: '20px' }}>
                                <Typography style={{ fontSize: '10px', textAlign: 'center', letterSpacing: '3px' }}>
                                    SPEND
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>
                                    Spend your crypto like cash
                                </Typography>
                                <Spacebox padding="5px" />
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    <small>
                                        Use the Mercuryo Card to spend crypto, precious metals and other assets anywhere you want. <b>coming soon</b>
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ padding: '20px' }}>
                                <Typography style={{ fontSize: '10px', textAlign: 'center', letterSpacing: '3px' }}>
                                    TRACK
                                </Typography>
                                <Typography variant="h6" style={{ textAlign: 'center' }}>
                                    Track your investment
                                </Typography>
                                <Spacebox padding="5px" />
                                <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
                                    <small>
                                        Track your price movements with targeted notifications, set up price alerts to move with the market or HODL as long as you want
                                    </small>
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-grey white">
                    <Container>
                        <Spacebox padding="20px" />
                        <div style={{ padding: "20px 20px 0px 20px", borderRadius: '20px' }} className="grey">
                            <Grid grid={isMobile ? 1 : 2} gap="20px" className="">
                                <div className="feature-div">
                                    <span className="white" style={{ padding: '10px', borderRadius: '5px', display: 'block', width: 'fit-content' }}>
                                        EXCLUSIVE FOR AUSTRIA
                                    </span>
                                    <Spacebox padding="10px" />
                                    <Typography variant="h2" style={{ fontSize: '25px' }}>
                                        Simplifying crypto tax reporting
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <Typography>
                                        You can now leverage our streamlined process that’s designed to be fully compliant with new Austrian tax regulations, while also helping you maximise your potential returns.
                                    </Typography>
                                </div>
                                <img src="/assets/tax.png" alt="payment-method" className="margin-auto hide-on-med-and-down" style={{ width: '100%', display: 'block' }} />
                                {isMobile && <Spacebox padding="10px" />}
                            </Grid>
                        </div>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 4} gap="20px">
                            <div className="loading-border">
                                <div className="grey" style={{ borderRadius: '20px', padding: '20px' }}>
                                    <Typography variant="h6">
                                        Do it all on Mercuryo
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <Typography variant="caption">
                                        <small>
                                            You can invest in crypto alongside your stocks* and metals – all in one place.
                                        </small>
                                    </Typography>
                                </div>
                            </div>
                            <div className="loading-border">
                                <div className="grey" style={{ borderRadius: '20px', padding: '20px' }}>
                                    <Typography variant="h6">
                                        Invest your way
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <Typography variant="caption">
                                        <small>
                                            Easily swap your cryptocurrencies with stocks*, precious metals and other Mercuryo assets.
                                        </small>
                                    </Typography>
                                </div>
                            </div>
                            <div className="loading-border">
                                <div className="grey" style={{ borderRadius: '20px', padding: '20px' }}>
                                    <Typography variant="h6">
                                        Set Up a saving plan
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <Typography variant="caption">
                                        <small>
                                            Set up automatic and recurring daily, weekly or monthly payments and invest in your favourite digital assets over time.
                                        </small>
                                    </Typography>
                                </div>
                            </div>
                            <div className="loading-border">
                                <div className="grey" style={{ borderRadius: '20px', padding: '20px' }}>
                                    <Typography variant="h6">
                                        More money in your portfolio
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <Typography variant="caption">
                                        <small>
                                            No deposit and withdrawal fees, on all payment methods for all currencies.
                                        </small>
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="10px" />
                        <Typography style={{ textAlign: 'center', fontSize: '10px' }}>
                            <small style={{ letterSpacing: '3px', textTransform: 'uppercase' }}>
                                HOW IT WORKS
                            </small>
                        </Typography>
                        <Typography variant="h2" style={{ textAlign: 'center', fontSize: '32px' }}>
                            Get started in minutes
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 4} gap="20px">
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/register.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Register
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Sign up to create your free Mercuryopal account
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/verify.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Verify
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Verify your identity with one of our trusted verification partners
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/deposit.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Deposit
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Deposit your funds securely through our supported options
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/trade.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Trade
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Buy, sell and swap digital assets 24/7
                                    </small>
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <Footer className="pre-grey white" />
            </div>
        </Layout>
    );
}

export default Cryptocurrency;