import { Container, Typography } from "@mui/material";
import Spacebox from "./styles/Spacebox";
import Logo from "./Logo";
import Grid from "./styles/Grid";
import { Link } from "react-router-dom";
import Flexbox from "./Flexbox";
import { isMobile } from "react-device-detect";

const Footer = ({ className }) => {
    const invest_links = [
        { title: 'Cryptocurrency', link: '/cryptocurrency' }, { title: 'Stocks', link: '/stocks' }, { title: 'EFTs', link: '/efts' }, { title: 'Metals', link: '/metals' }, { title: 'Commodities*', link: '/commodities' }
    ]

    const company_links = [
        { title: 'About', link: '/about' },
        { title: 'Explore', link: 'https://mercuryo.io/explore' },
    ]

    const follow_links = [
        { title: 'LinkedIn', link: 'https://www.linkedin.com/company/mercuryo-io' },
        { title: 'Twitter', link: 'https://twitter.com/Mercuryo_io' },
    ]
    const policies = [
        { title: 'Cookie Policy', link: 'https://mercuryo.io/legal/cookies/' },
        { title: 'Terms of Service', link: 'https://mercuryo.io/legal/terms/' },
        { title: 'Privacy Policy', link: 'https://mercuryo.io/legal/privacy/' },
    ]
    return (
        <section className={`section footer ${className}`}>
            <Container>
                <Spacebox padding="10px" />
                <Logo />
                <Spacebox padding="20px" />
                <Grid grid={isMobile ? 3 : 4} gap="10px">
                    <div>
                        <Typography style={{ fontSize: '18px' }}>
                            Invest
                        </Typography>
                        {invest_links.map((link, index) => (
                            <div key={index}>
                                <Spacebox padding="5px" />
                                <Link style={{ color: '#00000070', fontSize: '15px' }} to={link.link}>{link.title}</Link>
                            </div>
                        ))}
                    </div>
                    <div>
                        <Typography style={{ fontSize: '18px' }}>
                            Company
                        </Typography>
                        {company_links.map((link, index) => (
                            <div key={index}>
                                <Spacebox padding="5px" />
                                <Link style={{ color: '#00000070', fontSize: '15px' }} to={link.link}>{link.title}</Link>
                            </div>
                        ))}
                    </div>
                    <div>
                        <Typography style={{ fontSize: '18px' }}>
                            Follow
                        </Typography>
                        {follow_links.map((link, index) => (
                            <div key={index}>
                                <Spacebox padding="5px" />
                                <Link style={{ color: '#00000070', fontSize: '15px' }} to={link.link}>{link.title}</Link>
                            </div>
                        ))}
                    </div>
                </Grid>
                <Spacebox padding="30px" />
                <small style={{opacity: .5}}>
                    Cryptocurrency buy and sell services are provided by MoneyMaple Tech LTD registered at 810 Quayside Drive, suite 205, New Westminster, BC V3M 6B9, Canada under incorporation number BC1306168, MSB registration number: M21565803.
                    IBAN services are provided by Monetley LTD, operating under the laws of England and Wales, registered at 2nd Floor, Berkeley Square House, Berkeley Square, London, United Kingdom, W1J 6BD, registered in the FCA as Electronic Money Institution (EMI) (FCA Reference number: 900921).
                    Be aware of scams: Mercuryo provides its services only via Mercuryo.io, Mercuryopal.com and the official mobile application. Mercuryo is not affiliated with any other similar website domains and is not responsible for any acts taken by their owners.
                </small>
                <Spacebox padding="20px" />
                <Flexbox alignItems="center">
                    {policies.map((policy, index) => (
                        <Link key={index} style={{ color: '#00000070', fontSize: '15px', display: 'inline-block', marginRight: '20px' }} to={policy.link}>{policy.title}</Link>
                    ))}
                </Flexbox>
            </Container>
        </section>
    );
}

export default Footer;