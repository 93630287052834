import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Footer from "../components/Footer";
import Card from "../components/Card";


const Features = ({ title }) => {
    document.querySelector('title').innerHTML = title

    const features = [
        {
            heading: 'Mercuryo Pal Crypto Index',
            sub: 'Auto-invest in the whole crypto market',
            image: '/assets/mercuryo-crypto-index.png'
        },
        {
            heading: 'Savings Plan',
            sub: 'Save effortlessly with a savings plan',
            image: '/assets/mercuryo-saving-plan.png'
        },
    ]

    const reward = [
        {
            heading: 'Best Rewards',
            sub: 'Earn rewards and exclusive perks',
            image: '/assets/mercuryo-rewards.png'
        },
    ]

    const money = [
        {
            heading: 'Swap',
            sub: 'Exchange digital assets instantly',
            image: '/assets/mercuryo-swap.png'
        },
        {
            heading: 'Payment',
            sub: 'Send money with Mercuryo',
            image: '/assets/mercuryo-payment.png'
        },
    ]

    const refer = [
        {
            heading: 'TELL-A-FRIEND PROGRAMME',
            sub: 'Invite your friends, earn rewards',
            image: '/assets/mercuryo-message.png'
        },
        {
            heading: 'Affiliate Programme',
            sub: 'Become a partner and earn commission',
            image: '/assets/mercuryo-affiliate.png'
        },
    ]

    return (
        <Layout>
            <div className="metfeaturesals-page">
                <section>
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography className="bold" style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px', textAlign: 'center' }}>Products and features
                            <br />to get the most out <br />Mercuryopal</Typography>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white white">
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography variant="h2" className="bold" style={{ fontSize: '32px' }}>
                            Popular features
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" >
                            {features.map((feature, index) => (
                                <Card heading={feature.heading} sub={feature.sub} image={feature.image} key={index} />
                            ))}
                        </Grid>
                        <Spacebox padding="40px" />
                        <Typography variant="h2" className="bold" style={{ fontSize: '32px' }}>
                            Rewards
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" >
                            {reward.map((rd, index) => (
                                <Card heading={rd.heading} sub={rd.sub} image={rd.image} key={index} />
                            ))}
                        </Grid>
                        <Spacebox padding="40px" />
                        <Typography variant="h2" className="bold" style={{ fontSize: '32px' }}>
                            Save time & money
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" >
                            {money.map((mn, index) => (
                                <Card heading={mn.heading} sub={mn.sub} image={mn.image} key={index} />
                            ))}
                        </Grid>
                        <Spacebox padding="40px" />
                        <Typography variant="h2" className="bold" style={{ fontSize: '32px' }}>
                            Make money
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" >
                            {refer.map((ref, index) => (
                                <Card heading={ref.heading} sub={ref.sub} image={ref.image} key={index} />
                            ))}
                        </Grid>
                        <Spacebox padding="40px" />
                    </Container>
                </section>
                <Footer className="pre-grey white" />
            </div>
        </Layout>
    );
}

export default Features;