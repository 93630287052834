import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Divider, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomButton from "../components/styles/Custombutton";
import Footer from "../components/Footer";
import FAQ from "../components/Faq";

const Stocks = ({ title }) => {
    document.querySelector('title').innerHTML = title
    const faqs = [
        {
            title: 'What is the minimum buy/sell amount?',
            content: 'The minimum amount for a single buy or sell transaction is as little as $1,300. It means you can start investing in Amazon or Tesla from just $1,300.'
        },
        {
            title: 'What are the main advantages of investing in stocks/ETFs at Mercuryo Pal?',
            content: 'Mercuryo Pal Stocks is a completely new and innovative way to participate in the price movements of stocks and ETFs*. You can now invest in assets on a fractional basis, commission-free with tight spreads, any time you want. Want to buy $3,000 worth of Mercuryo Stocks in Amazon on a Saturday evening? It’s now possible. You will also receive the dividends.'
        },
        {
            title: 'What does fractional trading mean?',
            content: 'Most traditional brokers only offer full stocks. Mercuryo gives you the possibility to invest in small fractions of any stock* available on Mercuryo Pal. These investments are completed via derivative contracts, meaning you invest in the actual underlying stocks or ETFs - and Mercuryo holds 100% of the underlying stocks or ETFs.'
        },
        {
            title: 'Who can invest in Mercuryo Pal Stocks?',
            content: 'Anyone with residence in a European country can invest in stocks and ETFs* on Mercuryo Pal.'
        },
        {
            title: 'What about dividends?',
            content: 'By holding Mercuryo Pal Stocks*, you are entitled to participate indirectly, virtually and proportionally in the respective dividends or fund distributions. You will receive these virtual dividends automatically and credited directly to your Mercuryo Pal account. When you hold fractional stocks, you will receive dividends proportionate to your holdings. For example, if you are holding 0.5 units, you will receive 50% of the dividend payout. All you need to do is hold the asset during the time of the dividend event. '
        },
    ]
    return (
        <Layout>
            <div className="stocks-page">
                <section>
                    <Container>
                        <Grid gap="20px" grid={isMobile ? 1 : 2} style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>STOCKS* & ETFS* • 24/7 • COMMISSION FREE</Typography>
                                <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>Wall Street without {!isMobile && <br />}the walls</Typography>
                                <Spacebox padding="10px" />
                                <small>No matter the stock price, Our professional traders have got you covered.</small>
                                <Spacebox padding="10px" />
                                {!isMobile && <CustomButton
                                    style={{ padding: '20px 60px', background: 'var(--primary)', borderRadius: '5px', color: 'white' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                                {isMobile && (
                                    <div>
                                        <img src="/assets/stocks-banner.png" alt="bitcoin" style={{ width: '60%', display: 'block', margin: 'auto' }} />
                                        <small style={{ opacity: .3, display: 'block', textAlign: 'center' }}>Past performance is fictional, for illustrative purposes only and no indication of future performance.</small>
                                        <Spacebox padding="10px" />
                                    </div>
                                )}
                                {isMobile && <CustomButton
                                    style={{ padding: '20px 0px', background: 'var(--primary)', borderRadius: '5px', color: 'white', width: '100%' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                            </div>
                            {!isMobile && (
                                <div>
                                    <img src="/assets/stocks-banner.png" alt="bitcoin" style={{ width: '60%', display: 'block', margin: 'auto' }} />
                                    <small style={{ opacity: .3, display: 'block', textAlign: 'center' }}>Past performance is fictional, for illustrative purposes only and no indication of future performance.</small>
                                </div>
                            )}
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            {!isMobile && <div>
                                <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/invest.png" alt="invest" />
                            </div>}
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>FRACTIONAL ASSETS</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Invest in fractions or the whole thing</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div>
                                    <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/invest.png" alt="invest" />
                                </div>}
                                <Spacebox padding="10px" />
                                <span>
                                    Build your portfolio by investing in full shares or fractions of your favorite companies over time for as little as $1,300.
                                </span>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>FINVEST 24/7</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Markets closed? Not anymore</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div>
                                    <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/markets-closed.png" alt="markets closed" />
                                    <small style={{ display: 'block', textAlign: 'center', opacity: .3 }}>
                                        Past performance is fictional, for illustrative purposes only
                                        and no indication of future performance.
                                    </small>
                                </div>}
                                <Spacebox padding="10px" />
                                <span>
                                    Invest whenever you want, even when the traditional stock market is closed.
                                </span>
                            </div>
                            {!isMobile && <div>
                                <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/markets-closed.png" alt="markets closed" />
                                <small style={{ display: 'block', textAlign: 'center', opacity: .3 }}>
                                    Past performance is fictional, for illustrative purposes only
                                    and no indication of future performance.
                                </small>
                            </div>}
                        </Grid>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            {!isMobile && <div>
                                <img style={{ width: '70%', display: 'block', margin: 'auto' }} src="/assets/fully-backed.png" alt="invest" />
                            </div>}
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>SECURE WITH REDUCED COUNTERPARTY RISK</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Fully backed by physical assets</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div>
                                    <img style={{ width: '100%', display: 'block', margin: 'auto' }} src="/assets/fully-backed.png" alt="invest" />
                                </div>}
                                <Spacebox padding="10px" />
                                <span>
                                    Your investments are physically backed and held by a custody bank - keeping them safe at all times and reducing counterparty risk.
                                </span>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>ZERO COMMISSIONS</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Commission-free trading with tight spreads</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                    <video style={{ right: '-5px', position: 'relative' }} src="/videos/comission-free.mov" loop autoPlay muted controls={false} ></video>
                                </div>}
                                <Spacebox padding="10px" />
                                <ul>
                                    {["No account fees", "No management fees", "No list fees", "No FX fees", "No fees on distributions or other payouts", "We don't charge commissions on any trades, ever."].map((item, index) => (
                                        <li key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {!isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <video style={{ right: '-5px', position: 'relative' }} src="/videos/comission-free.mov" loop autoPlay muted controls={false} ></video>
                            </div>}
                        </Grid>
                        <Spacebox padding="10px" />
                        <Divider sx={{ opacity: 0.6 }} />
                        <Spacebox padding="10px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>24/7 • FRACTIONAL • ZERO COMMISSIONS • SECURITY</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">How does this work?</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                    <video style={{ right: '0px', position: 'relative', width: '100%' }} src="/videos/how-it-works.mp4" loop autoPlay muted controls={false} ></video>
                                </div>}
                                <Spacebox padding="10px" />
                                <ol>
                                    {["Mercuryo Pal purchases the underlying stocks through partner exchanges", "Our custody bank partners hold the stocks for safekeeping", "You invest in fractions of the stocks via derivatives."].map((item, index) => (
                                        <li key={index}>
                                            {item}
                                        </li>
                                    ))}
                                </ol>
                            </div>
                            {!isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <video style={{ right: '0px', position: 'relative', width: '100%' }} src="/videos/how-it-works.mp4" loop autoPlay muted controls={false} ></video>
                            </div>}
                        </Grid>
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container maxWidth="md" >
                        <Spacebox padding="20px" />
                        <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>FAQ</Typography>
                        <Spacebox padding="10px" />
                        <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Common questions from investors like you</Typography>
                        <Spacebox padding="10px" />
                        <div className="">
                            {faqs.map((faq, index) => (
                                <FAQ title={faq.title} content={faq.content} key={index} />
                            ))}
                        </div>
                    </Container>
                </section>
                <Footer className="pre-grey white" />
            </div>
        </Layout>
    );
}

export default Stocks;