import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import Footer from "../components/Footer";
import Flexbox from "../components/Flexbox";


const Company = ({ title }) => {
    document.querySelector('title').innerHTML = title

    return (
        <Layout>
            <div className="metfeaturesals-page">
                <section style={{ overflow: 'hidden' }}>
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="10px">
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>ABOUT US</Typography>
                                <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>
                                    Business payments without borders. <br />Crypto powered by
                                    <span style={{ background: 'var(--secondary)', color: 'white' }}> Mercuryo</span>
                                </Typography>
                                <Spacebox padding="10px" />
                                <small>
                                    Mercuryo is a global payments ecosystem, building and harmonising crypto and fiat solutions, which enable businesses across the globe to unlock and harness the power of crypto payments
                                </small>
                                <Spacebox padding="20px" />
                            </div>
                            {<div className="" style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                <img className="" style={{ width: '80%', display: 'block', margin: 'auto', transform: 'scale(1.1)', filter: 'brightness(75%)' }} src="/assets/header-star.png" alt="star" />
                            </div>}
                        </Grid>
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            <div>
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Our business</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                    <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/header-btc.png" alt="business" />
                                </div>}
                                <Spacebox padding="10px" />
                                <Typography style={{ lineHeight: '30px' }}>
                                    was born out of a firm belief that cryptocurrency was never designed to be solely a tradable asset, but a fundamental tool that is revolutionising the financial services landscape, overhauling legacy systems and institutions
                                </Typography>
                            </div>
                            {!isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/header-btc.png" alt="business" />
                            </div>}
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-grey white">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="10px" style={{ alignItems: 'center' }}>
                            {!isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/wallet (1).png" alt="business" />
                            </div>}
                            <div>
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Our Market</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                    <img style={{ width: '60%', display: 'block', margin: 'auto' }} src="/assets/wallet (1).png" alt="business" />
                                </div>}
                                <Spacebox padding="10px" />
                                <Typography style={{ lineHeight: '30px' }}>
                                    leading on- and off-ramp products are already reinventing the ease
                                    of making payments for over 250+ businesses around the world.
                                    And we’re not stopping there!
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            <div>
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Our Team</Typography>
                                <Spacebox padding="10px" />
                                {isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                    <img style={{ width: '80%', display: 'block', margin: 'auto' }} src="/assets/msgs.png" alt="business" />
                                </div>}
                                <Spacebox padding="10px" />
                                <Typography>
                                    We’ve grown from a team of just five people to now hundreds of awe-inspiring professionals from all over the world. <br /><br />
                                    In the same way we believe all payments should be borderless, Mercurians are global too. <br />
                                    From London, to New York, to Bangkok, we’re a diverse team extremely proud of the journey we’re on
                                </Typography>
                            </div>
                            {!isMobile && <div style={{ overflow: '', width: '100%', margin: 'auto' }}>
                                <img style={{ width: '80%', display: 'block', margin: 'auto' }} src="/assets/msgs.png" alt="business" />
                            </div>}
                        </Grid>
                    </Container>
                </section>
                <section className="section pre-grey white">
                    <Container>
                        <Spacebox padding="20px" />
                        <Typography style={{ fontSize: isMobile ? '30px' : '60px', lineHeight: isMobile ? '45px' : '60px' }}>Meet our {!isMobile && <br />}Executive team</Typography>
                        <Spacebox padding="10px" />
                        <Grid grid={isMobile ? 1 : 3} gap="20px">
                            <div>
                                {!isMobile && <Spacebox padding="20px" />}
                                <img src="/assets/greg.jpeg" alt="greg" style={{ width: '100%', borderRadius: '20px' }} />
                                <Spacebox padding="10px" />
                                <Flexbox alignItems="center">
                                    <Typography style={{ fontSize: '30px' }}>
                                        Greg
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <img src="/svgs/executive-star.svg" style={{ width: '20px' }} alt="star" />
                                    <Spacebox padding="5px" />
                                    <small>Co-Founder and COO</small>

                                </Flexbox>
                            </div>
                            <div>
                                <img src="/assets/lindsay.jpeg" alt="greg" style={{ width: '100%', borderRadius: '20px' }} />
                                <Spacebox padding="10px" />
                                <Flexbox alignItems="center">
                                    <Typography style={{ fontSize: '30px' }}>
                                        Lindsay
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <img src="/svgs/executive-star.svg" style={{ width: '20px' }} alt="star" />
                                    <Spacebox padding="5px" />
                                    <small>Chef Financial Officer </small>

                                </Flexbox>
                            </div>
                            <div>
                                {!isMobile && <Spacebox padding="20px" />}
                                <img src="/assets/aviessa.jpeg" alt="greg" style={{ width: '100%', borderRadius: '20px' }} />
                                <Spacebox padding="10px" />
                                <Flexbox alignItems="center">
                                    <Typography style={{ fontSize: '30px' }}>
                                        Aviessa
                                    </Typography>
                                    <Spacebox padding="5px" />
                                    <img src="/svgs/executive-star.svg" style={{ width: '20px' }} alt="star" />
                                    <Spacebox padding="5px" />
                                    <small>Executive Director Sales</small>

                                </Flexbox>
                            </div>
                        </Grid>
                    </Container>
                    <Spacebox padding="20px" />
                </section>
                <Footer className="pre-white grey" />
            </div>
        </Layout>
    );
}

export default Company;