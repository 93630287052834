import { isMobile } from "react-device-detect";
import Layout from "../components/Layout";
import Grid from "../components/styles/Grid";
import { Container, Typography } from "@mui/material";
import Spacebox from "../components/styles/Spacebox";
import CustomButton from "../components/styles/Custombutton";
import Footer from "../components/Footer";
import FAQ from "../components/Faq";

const Commodities = ({ title }) => {
    document.querySelector('title').innerHTML = title
    const faqs = [
        {
            title: 'What does ‘physically backed’ mean?',
            content: "You buy shares of real gold, silver, palladium and platinum bars from our partners GVS, philoro and pro aurum. The bars are safely stored with Brink's in a secure vault in Switzerland. When you buy precious metals on Mercuryopal, you are the owner of a specific share of real gold, silver, palladium and platinum, stored in a secure vault.",
            index: 1
        },
        {
            title: 'How are the precious metals I buy on Mercuryopal stored?',
            content: "With Mercuryopal Metals we take care of all the security. All the precious metals you buy on Mercuryopal are stored in Switzerland, in a secure vault where it’s 100% insured. This is the perfect convenience solution as you don’t have to go to a gold trader to buy physical gold or silver. Storing precious metals at home can be a security risk. You need a special and usually expensive safe in order to get it covered by insurance, which again usually costs money. Alternatively, you can store it in a deposit box at a bank, which means that you have to go there in person and you also have to pay comparably high fees for storing it there.",
            index: 2
        },
        {
            title: 'Why is Mercuryopal Metals duty free?',
            content: 'The vault where the precious metals you buy on Mercuryopal is stored is a duty-free storage facility in Switzerland. This means that there is no value added tax (VAT) on purchases–even for silver, palladium and platinum.',
            index: 3
        },
        {
            title: 'Can I set up a savings plan for precious metals?',
            content: 'Yes. You can buy gold, silver, palladium and platinum on a daily, weekly, biweekly or monthly basis. Set up as many savings plans as you want and combine gold and silver purchases with your other regular digital asset investments. This way you can diversify your portfolio while at the same time benefit from the cost average effect.',
            index: 4
        },
        {
            title: "What does 'own what you buy' mean?",
            content: "If you buy precious metals on Mercuryopal, you become the legal owner. In the event of bankruptcy on the part of Mercuryo, the precious metals remain in your possession. This means that your property will not be reduced in the event of Mercuryo's bankruptcy. You can also find further information in our Mercuryo Metals terms & conditions.",
            index: 5
        },
    ]
    return (
        <Layout>
            <div className="metals-page">
                <section>
                    <Container>
                        <Grid gap="20px" grid={isMobile ? 1 : 2} style={{ alignItems: 'center' }}>
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>GOLD • PLATINUM • SILVER • PALLADIUM</Typography>
                                <Typography style={{ fontSize: isMobile ? '40px' : '60px', lineHeight: isMobile ? '45px' : '70px' }}>The simple way to buy and {!isMobile && <br />}sell commodities*</Typography>
                                <Spacebox padding="10px" />
                                <small>Start trading key commodities* like oil, natural gas, aluminium, wheat and more, from as little as $1. </small>
                                <Spacebox padding="10px" />
                                {!isMobile && <CustomButton
                                    style={{ padding: '20px 60px', background: 'var(--primary)', borderRadius: '5px', color: 'white' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                                {isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                    <video style={{ right: '-5px', bottom: '-5px', position: 'relative', width: '100%' }} src="/videos/commodity-banner.mov" loop autoPlay muted controls={false} ></video>
                                </div>}
                                {isMobile && <Spacebox padding="10px" />}
                                {isMobile && <CustomButton
                                    style={{ padding: '20px 0px', background: 'var(--primary)', borderRadius: '5px', color: 'white', width: '100%' }}
                                    handleClick={() => window.location.href = 'https://invest.mercuryopal.com'}
                                >
                                    Start Investing
                                </CustomButton>}
                            </div>
                            {!isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <video style={{ right: '-5px', bottom: '-5px', position: 'relative' }} src="/videos/commodity-banner.mov" loop autoPlay muted controls={false} ></video>
                            </div>}
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="10px" />
                        <Grid grid={isMobile ? 1 : 3} gap="20px">
                            <div style={{ borderRight: '1px solid #f0f0f0', padding: '20px' }}>
                                <Typography variant="subtitle2" style={{ textAlign: '' }}>
                                    <small>
                                        Earn from short term <br />price movements
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ borderRight: '1px solid #f0f0f0', padding: '20px' }}>
                                <Typography variant="subtitle2" style={{ textAlign: '' }}>
                                    <small>
                                        Invest 24/7, <br />even on weekends
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ padding: '20px' }}>
                                <Typography variant="subtitle2" style={{ textAlign: '' }}>
                                    <small>
                                        Diversify and hedge <br />against inflation
                                    </small>
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="10px" />
                    </Container>
                </section>
                <section className="section pre-grey white">
                    <Container>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            {<div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <img style={{ width: '100%', display: 'block', margin: 'auto' }} src="/assets/wallet.png" alt="vault" />
                            </div>}
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>ALL IN ONE PLACE</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Trade oil, gas and 28 other commodities*</Typography>
                                <Spacebox padding="10px" />
                                <span>
                                    Get access to the commodities* you want — right alongside your crypto, stocks* and metals.
                                </span>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            {isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <img style={{ width: '100%', display: 'block', margin: 'auto' }} src="/assets/assets.png" alt="vault" />
                                <Spacebox padding="10px" />
                            </div>}
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>DIVERSIFY YOUR PORTFOLIO</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Hedge against inflation</Typography>
                                <Spacebox padding="10px" />
                                <span>
                                    Counter rising living costs and benefit from the short term price movements of key commodities*.
                                </span>
                            </div>
                            {!isMobile && <div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <img style={{ width: '100%', display: 'block', margin: 'auto' }} src="/assets/assets.png" alt="vault" />
                            </div>}
                        </Grid>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 2} gap="20px" style={{ alignItems: 'center' }}>
                            {<div style={{ overflow: 'hidden', width: 'fit-content', margin: 'auto' }}>
                                <img style={{ width: '100%', display: 'block', margin: 'auto' }} src="/assets/1-euro.png" alt="1 euro" />
                            </div>}
                            <div>
                                <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>START SMALL, THINK BIG</Typography>
                                <Spacebox padding="10px" />
                                <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Start with €1</Typography>
                                <Spacebox padding="10px" />
                                <span>
                                    Like all Mercuryopal assets, you can start investing in your favourite commodities* with as little as €1.
                                </span>
                            </div>
                        </Grid>
                    </Container>
                </section>
                <section className="section pre-white grey">
                    <Container>
                        <Spacebox padding="10px" />
                        <Typography style={{ textAlign: 'center', fontSize: '10px' }}>
                            <small style={{ letterSpacing: '3px', textTransform: 'uppercase' }}>
                                HOW IT WORKS
                            </small>
                        </Typography>
                        <Typography variant="h2" style={{ textAlign: 'center', fontSize: '32px' }}>
                            Get started in minutes
                        </Typography>
                        <Spacebox padding="20px" />
                        <Grid grid={isMobile ? 1 : 4} gap="20px">
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/register.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Register
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Sign up to create your free Mercuryopal account
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/verify.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Verify
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Verify your identity with one of our trusted verification partners
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/deposit.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Deposit
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Deposit your funds securely through our supported options
                                    </small>
                                </Typography>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div className="margin-auto" style={{ width: 'fit-content', padding: '10px', borderRadius: '10px', background: '#f0f0f0' }}>
                                    <img src="/svgs/trade.svg" alt="" style={{ width: '50px' }} />
                                </div>
                                <Spacebox padding="10px" />
                                <Typography variant="h6" textAlign="center">
                                    Trade
                                </Typography>
                                <Spacebox padding='0px' />
                                <Typography variant="caption" textAlign="center">
                                    <small>
                                        Buy, sell and swap digital assets 24/7
                                    </small>
                                </Typography>
                            </div>
                        </Grid>
                        <Spacebox padding="20px" />
                    </Container>
                    <Container maxWidth="md" >
                        <Spacebox padding="20px" />
                        <Typography className="thin" style={{ letterSpacing: '3px', textTransform: 'uppercase', fontSize: '10px' }}>FAQ</Typography>
                        <Spacebox padding="10px" />
                        <Typography style={{ fontSize: isMobile ? '30px' : '40px', lineHeight: isMobile ? '45px' : '45px' }} className="bold">Common questions from investors like you</Typography>
                        <Spacebox padding="10px" />
                        <div className="">
                            {faqs.map((faq, index) => (
                                <FAQ title={faq.title} content={faq.content} key={index} />
                            ))}
                        </div>
                    </Container>
                    <Spacebox padding="20px" />
                </section>
                <Footer className="pre-grey white" />
            </div>
        </Layout>
    );
}

export default Commodities;