import Spacebox from "./styles/Spacebox";
import '../css/card.css'
import Grid from "./styles/Grid";
import { isMobile } from "react-device-detect";

const Card = ({ heading, sub, image }) => {
    return (
        <div className="card">
            <div style={{ borderRadius: '20px', padding: '20px 20px 0px 20px' }} className="grey">
                <Grid grid={isMobile ? 1 : 2} gap="10px">
                    <div>
                        <small style={{ padding: '10px', borderRadius: '5px', display: 'block', width: 'fit-content', textTransform: 'uppercase', background: '#DEDEDF', fontSize: '12px' }}>
                            {heading}
                        </small>
                        <Spacebox padding="5px" />
                        <span> {sub} </span>
                        <Spacebox padding="5px" />
                        <a href="https://invest.mercuryopal.com/">Learn More</a>
                    </div>
                    <div className="img-holder" style={{ textAlign: 'right' }}>
                        <img src={image} alt={heading} style={{ width: isMobile ? '100px' : '200px' }} />
                    </div>
                </Grid>
            </div>
        </div>
    );
}

export default Card;