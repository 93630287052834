import { InfoOutlined } from "@mui/icons-material";
import Flexbox from "./Flexbox";
import Spacebox from "./styles/Spacebox";

const Notice = ({ color, children }) => {

    const colorType = {
        'warning': '#FE9427',
        'error': '#ff0000',
        'success': '#00B77B',
        'info': '#5874A3'
    }

    const colorCheck = color => {
        switch (color) {
            case "warning":
                return colorType[color]
            case "error":
                return colorType[color]
            case "success":
                return colorType[color]
            case "info":
                return colorType[color]
            default:
                return colorType["warning"];
        }
    }
    return (
        <div className="notice-box" style={{ background: colorCheck(color) + "10", border: `2px solid ${colorCheck(color)}`, width: 'fit-content', borderRadius: '5px', padding: '10px 20px', margin: '20px 0px' }}>
            <div className="notice" style={{ color: colorCheck(color) }}>
                <Flexbox alignItems="center">
                    <InfoOutlined style={{ color: colorCheck(color), fontSize: '16px' }} />
                    <Spacebox padding="5px" />
                    <small>
                        {children}
                    </small>
                </Flexbox>
            </div>
        </div>
    );
}

export default Notice;