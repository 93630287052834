import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Logo = () => {

    const navigate = useNavigate()

    return (
        <div className="logo-text" style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
            <Typography className="logo-text" style={{ lineHeight: "0.5em", fontSize: '28px' }}>
                mercuryo
            </Typography>
            <small style={{ letterSpacing: '3px', fontSize: '8px' }}>INVESTMENT • PAL</small>
        </div>
    );
}

export default Logo;